export const environment = {
  production: true,
  isLive: false,
  // API_ENDPOINT: 'http://192.168.4.96/ebay/public/',

  // API_ENDPOINT: 'http://192.168.0.222/remote/public/',
  // API_ENDPOINT: "https://amazon-sales-channel.demo.cedcommerce.com/remote/public/",
  // API_ENDPOINT: 'https://connector-dev.demo.cedcommerce.com/remote/public/',
  // API_ENDPOINT: 'https://onyx.demo.sellernext.com/remote/public/',
  // API_ENDPOINT: 'https://remote.connector.sellernext.com/',
  //API_ENDPOINT: 'http://remote.local.cedcommerce.com:8080/',

  API_ENDPOINT: "http://remote.local.cedcommerce.com/",
  // API_ENDPOINT: "https://amazon-sales-channel.demo.cedcommerce.com/remote/public/",
  // API_ENDPOINT: 'https://connector-dev.demo.cedcommerce.com/remote/public/',
  // API_ENDPOINT: 'https://remote.hubspot.cedcommerce.com/',
  // API_ENDPOINT: 'https://remote.connector.sellernext.com/',
  // API_ENDPOINT: 'https://remote.local.cedcommerce.com/',
  AppName: "API Connector",
  // confirmation_link: 'http://192.168.4.93:3100/connector/app/auth/verify/',
  // Bearer: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiMSIsInJvbGUiOiJhcHAiLCJpYXQiOjE1MzI1MjM2MjUsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsImF1ZCI6ImV4YW1wbGUuY29tIiwibmJmIjoxNTMyNTIzNjI1LCJ0b2tlbl9pZCI6MTUzMjUyMzYyNX0.AWClFl356j90_bA8XGsjq-vwvUzRYuvsXkJBb3JnmnS3BpNxf0x9kyNyCi_H2iUK9FHaFBIHriIYeY5N4eePuc0HKkLdr_ZWKYiEYjXW0nfV0KGHBBW-WGPZuWUvW0SDEoALvNesd9OPBeMwmafMrfmV9FREtHKO1Poorw3Wne3rrC-U6q19s6O3BdCp15I78QD95KtrqGzY47NHIlp2g5wfS7lXFpCAag-p4nqEXW7mBi8G-L25HsDJ5T0FljqA2TWUy7h9V_a50TLQhIHN2CvqYKD-Ba82LkiQcmRbFG3L5XbHRAk8uQB5xbDmjGoCTgWfFtye17ItQG4uVUTnXA'
  Bearer: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjMyNDMyNjAyMjVmNjdkNzU1MDdjZjI5Iiwicm9sZSI6ImFkbWluIiwiZXhwIjoxNzE0MjAxNDAzLCJpc3MiOiJodHRwczpcL1wvYXBwcy5jZWRjb21tZXJjZS5jb20iLCJ0b2tlbl9pZCI6IjY0NGI2ZmJiNzhjYzJkZWNkYjBmMDRiMiJ9.lZNFqGejNMpeU8CjftMvC6r9TtAJAk5wOEhQAj6sz3IwpVcIzXQiLkgWtMtEgrYFSW1w77SDUh5YOEKOjoO9cgkKjGNdzKNoCXcrjBwETgW14-HI6tNwycMtUPI-CAUfLocImLL8eSXEfic8sgXdXumXZOf-t_byDeyL08sh-sFLbvi4GaKQHk72gwwYJ_09Gpt_NsO-NWsrzUSXwUgpDobk6GTv8vpXaF9e_dSxL6yS69byq4AcYmLJnw8vHEqf34oG_34aaqKx7Eu1Vh0sM-2IXb4a1s8D8puwkgK-bnY3GCpB75Gf81As8X8ExNvS_e1oIfi9U7yWAP1uoEXGCQ',
  multi_API_ENDPOINT: {
    BWP1: {
      name: "woombc ",
      API_ENDPOINT: "https://woombc-api.cedserver.com/",
      default: true,
      Bearer:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjYwM2JmMjI3NTdkYjYzMmE0MGU4YWE3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjYwM2M2ZjI5NWZjMTVlMzI2MDNiZDMyIn0.V6llUBTzD8GX33LFuSjnp6aAKR3DF11KHpI2sbD4YzM-RHxBX7PqBceZCjWTRoLhkGPu6rLHcnjAkx5rCqZFOBdnpg-uDgsqWMptvDORl-Enu9tLwswJxUQykLvn4Yw9x09BwNL7Dj0Xqr3y_XL72lP054_wvLf9ba_xCukW8VLsVTnmJNt0uDju1DSZCKVq2zY4QSrJZBXOLO-xHGUVZBDbeleThnOzti8FxhkBlCpZlVDSBZhzmLb5fCdl28DY2cKM_uYCypFIS_y5MnKlonqZY5MdCiBWkkVg0esyoDY7Qni5TWOb1YOfp6DeHqILWDb4SiiWn9HRMUdfHAhJag",
    },
  shein_woo_dev: {
      name: "SheinWooDev",
      API_ENDPOINT: 'https://shein-woocom-dev-api.cifapps.com/',
      default: true,
      Bearer: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjdlNTRjZWQ1OWUwNjk3OGU4MDUyMTRhIiwicm9sZSI6ImFkbWluIiwiZXhwIjoxNzc0NjgyNTU2LCJpc3MiOiJodHRwczpcL1wvYXBwcy5jZWRjb21tZXJjZS5jb20iLCJ0b2tlbl9pZCI6IjY3ZTY0ZTNjZmZjYTk4NTUyNTBjZWYxMiJ9.fUKG8g6naRe7UOiXwzAT8ObcJS8Txg5XEN9rWlqy_mBtduF89MznHaYu-HAYaaaXGRQ5yqc7QGca4KArbHQdJzhux8lT6fh-tAjW8pMRGgTwFC7-iHLd60B3tBgnWdl5YsCgkOo4UBjHELS7cDuFnANgfOtcuvV4R7dAUXFu-uqRNIhJ4ZAzapkss3P03_m3yAaVxc3AoXE13iHv_LQGHPDWA2z0GUI04ZRiYT4eoS--bl1lDVlQ5KoyIdhOq_7RXH5KBj08neoKnLtuE1uXlLbUJb5DfmthTXUrAvaZB37uemiDj-MD5bezl53WBr8XAzj2NxQKQRIVrAiLNzKZ2Q"
     },
  },
};
/* Bearer on the last line is App token for server */
